var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ms-login" },
    [
      _c("div", { ref: "topTitleRef", staticClass: "top-title" }),
      _c("div", { staticClass: "box-flex" }, [
        _c(
          "div",
          { ref: "loginBackgroundRef", staticClass: "login-background" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 经济开发区职业健康智慧管家系统 ")
            ]),
            _vm.loginType == 1 ? _c("div", [_vm._m(0), _vm._m(1)]) : _vm._e(),
            _vm.loginType == 2 ? _c("div", [_vm._m(2), _vm._m(3)]) : _vm._e(),
            _vm.loginType == 3 ? _c("div", [_vm._m(4), _vm._m(5)]) : _vm._e()
          ]
        ),
        _c("div", { staticClass: "content-box" }, [
          _c(
            "div",
            { staticClass: "login-back" },
            [
              _c(
                "el-form",
                {
                  key: "msForm",
                  ref: "msForm",
                  attrs: { model: _vm.msForm, rules: _vm.rules }
                },
                [
                  _c("div", { staticClass: "logo-title" }, [
                    _c("div", { staticStyle: { width: "100%" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.loginType == 1
                            ? "用人单位端"
                            : _vm.loginType == 2
                            ? "监管部门端"
                            : _vm.loginType == 3
                            ? "服务机构端"
                            : _vm.loginType == 4
                            ? "乡镇"
                            : "其他"
                        )
                      )
                    ]),
                    _c("div", { staticClass: "logo-title-bottom" })
                  ]),
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "userNo" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入登录账号" },
                              model: {
                                value: _vm.msForm.userNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.msForm, "userNo", $$v)
                                },
                                expression: "msForm.userNo"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "20px", height: "27px" },
                                attrs: {
                                  slot: "prefix",
                                  src: require("@/assets/images/login/ic_zhanghu.png")
                                },
                                slot: "prefix"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入登录密码",
                                type: _vm.view ? "password" : null
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.login("msForm")
                                }
                              },
                              model: {
                                value: _vm.msForm.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.msForm, "password", $$v)
                                },
                                expression: "msForm.password"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "20px", height: "27px" },
                                attrs: {
                                  slot: "prefix",
                                  src: require("@/assets/images/login/ic_mima.png")
                                },
                                slot: "prefix"
                              }),
                              _c("i", {
                                staticClass: "el-input__icon el-icon-view",
                                attrs: { slot: "suffix" },
                                on: {
                                  click: function($event) {
                                    return _vm.passSee()
                                  }
                                },
                                slot: "suffix"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "mobileTel4" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机号码后4位" },
                            model: {
                              value: _vm.msForm.mobileTel4,
                              callback: function($$v) {
                                _vm.$set(_vm.msForm, "mobileTel4", $$v)
                              },
                              expression: "msForm.mobileTel4"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("slider-check", {
                        ref: "slider",
                        staticStyle: { width: "354px" },
                        on: { change: _vm.sliderSuccess }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            background: "#1C7FF9",
                            height: "56px"
                          },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.login("msForm")
                            }
                          }
                        },
                        [_vm._v("登录")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "more", staticStyle: { "margin-top": "24px" } },
                [
                  _c(
                    "span",
                    { staticClass: "left", on: { click: _vm.toAccountAppeal } },
                    [_vm._v(" 账号申诉 ")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "right center left",
                      on: { click: _vm.toForgetPassword }
                    },
                    [_vm._v(" 忘记密码 ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "right", on: { click: _vm.toRegister } },
                    [_vm._v("注册账号")]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "flex-column-center" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        trigger: "hover",
                        "popper-class": "operat-popover"
                      }
                    },
                    [
                      _c("div", { staticClass: "flex-column-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "manual-css",
                            on: {
                              click: function($event) {
                                return _vm.downloadFile(_vm.contactEmployerPdf)
                              }
                            }
                          },
                          [_vm._v("用人单位")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "manual-css",
                            on: {
                              click: function($event) {
                                return _vm.downloadFile(_vm.contactSupervisePdf)
                              }
                            }
                          },
                          [_vm._v("监管部门")]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "bottom" }, [
        _c("div", { staticClass: "bottom-text" }, [
          _vm._v(
            "Copyright © " +
              _vm._s(_vm.domainNumber) +
              " | " +
              _vm._s(_vm.technicalSupport)
          )
        ])
      ]),
      _c("contact-us")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-middle" }, [
      _c("div", [
        _c("img", {
          staticStyle: { "margin-left": "100px" },
          attrs: {
            src: require("@/assets/images/login/ic_manage.png"),
            alt: ""
          }
        }),
        _vm._v(" 企业管理 ")
      ]),
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/login/ic_business.png"),
            alt: ""
          }
        }),
        _vm._v(" 业务申报 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-bcImg" }, [
      _c("img", {
        staticClass: "login-bc-pic",
        attrs: {
          src: require("@/assets/images/login/img_employer.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-middle" }, [
      _c("div", [
        _c("img", {
          staticStyle: { "margin-left": "100px" },
          attrs: {
            src: require("@/assets/images/login/ic_auxiliary.png"),
            alt: ""
          }
        }),
        _vm._v(" 辅助决策 ")
      ]),
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/login/ic_business.png"),
            alt: ""
          }
        }),
        _vm._v(" 统计分析 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-bcImg" }, [
      _c("img", {
        staticClass: "login-bc-pic",
        attrs: {
          src: require("@/assets/images/login/img_supervise.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-middle" }, [
      _c("div", [
        _c("img", {
          staticStyle: { "margin-left": "100px" },
          attrs: {
            src: require("@/assets/images/login/ic_detection.png"),
            alt: ""
          }
        }),
        _vm._v(" 场所检测 ")
      ]),
      _c("div", [
        _c("img", {
          attrs: { src: require("@/assets/images/login/ic_help.png"), alt: "" }
        }),
        _vm._v(" 惠企帮扶 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-bcImg" }, [
      _c("img", {
        staticClass: "login-bc-pic",
        attrs: {
          src: require("@/assets/images/login/img_service.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }