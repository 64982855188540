<template>
  <div class="wx-course-manage-edit">
    <el-form class="zwx-form edit-form" key="msForm" ref="msForm" :model="msForm" :rules="rules" label-position="right" @submit.native.prevent>
      <base-headline title="课程基本信息" class="no-top" />
      <div style="margin-top:10px">
        <div class="condition-row">
          <el-form-item label-width="110px" label="课程名称：" prop="courseName">
            <el-input style="width:640px !important" class="zwx-input" v-model.trim="msForm.courseName" placeholder="请输入" clearable maxlength="50" />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="课程简介：" prop="courseDesc">
            <el-input class="zwx-textarea" type="textarea" v-model.trim="msForm.courseDesc" style="width: 640px !important;" :autosize="{ minRows: 5, maxRows: 8 }" maxlength="2000" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="学时要求：">
            <div style="width:120px;color: #191919">{{ msForm.requiredClassHour }}</div>
          </el-form-item>
          <el-form-item label-width="80px" label="封面图片：">
            <file-upload ref="coverPictureRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG," @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="50 * 1024 * 1024" sizeInfo="50M">
              <!-- <el-button class="zwx-button zwx-button-text-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(msForm.annexList)">上传</el-button> -->
            </file-upload>
            <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
              <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.annexList, 0)">查看</el-button>
              <!-- <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(0)">删除</el-button> -->
            </div>
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="课程分类：">
            <div style="width:120px;color: #191919">{{ msForm.traineeTypeName }}</div>
          </el-form-item>
          <el-form-item label-width="80px" label="课程类型：">
            <div>{{ msForm.trainingTypeName }}</div>
          </el-form-item>
        </div>
        <!-- <div style="border-bottom:1px solid #e1e5f4" /> -->
        <div class="condition-row" v-if="ifShowOff">
          <el-form-item label-width="110px" label="线下课程：">
            <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addInfo">添加</el-button>
          </el-form-item>
        </div>
        <div v-if="ifShowOff" style="">
          <el-table class="zwx-table" row-key="rid" :data="msForm.offLineCoursewareList" border stripe tooltip-effect="light">
            <el-table-column prop="courseName" label="课程名称" min-width="300" header-align="center" align="left">
              <template slot-scope="scope">
                <el-form-item :id="'courseName' + scope.$index" :prop="'offLineCoursewareList.' + scope.$index + '.courseName'" :rules="rules.courseName">
                  <el-input class="zwx-input" v-model="scope.row.courseName" placeholder="" maxlength="50" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="classHour" label="学时" width="150" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :id="'classHour' + scope.$index" :prop="'offLineCoursewareList.' + scope.$index + '.classHour'" :rules="rules.classHour">
                  <el-input class="zwx-input" v-model.trim="scope.row.classHour" placeholder="" clearable @input="$validate.number(scope.row, 'classHour', 3, 1, 0, 100)" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" header-align="left" align="left">
              <template slot-scope="scope">
                <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteInfo(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="condition-row" style="margin-top:16px">
          <el-form-item label-width="110px" label="线上课程："></el-form-item>
        </div>
        <div style="">
          <el-table class="zwx-table" ref="chooseTableRef" row-key="uuid" :data="msForm.tbCoursewareInfoExpList" border stripe tooltip-effect="light" @selection-change="listSelectionChange" @select-all="allSelectChange" @select="rowSelectChange">
            <el-table-column key="selection" type="selection" width="55" header-align="center" align="center"></el-table-column>
            <el-table-column prop="coursewareType" label="类别" width="80" header-align="center" align="center">
              <template slot-scope="scope">
                {{ scope.row.coursewareType == 1 ? '标题' : scope.row.coursewareType == 2 ? '教材' : '考试' }}
              </template>
            </el-table-column>
            <el-table-column prop="coursewareTitle" label="内容" min-width="270" header-align="center" align="left">
              <template slot-scope="scope">{{ scope.row.coursewareTitle }}</template>
            </el-table-column>
            <el-table-column prop="classHour" label="学时" width="150" header-align="center" align="center">
              <template slot-scope="scope">
                {{ scope.row.classHour }}
              </template>
            </el-table-column>
            <el-table-column prop="requiredCourse" label="性质" header-align="left" align="left">
              <template slot-scope="scope">
                {{ scope.row.requiredCourse === true ? '必修' : scope.row.requiredCourse === false ? '选修' : '' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-form>
    <div style="margin-left: 40px;margin-top:10px;font-size:18px;color: #3A485E;">
      已选课程合计：必修
      <span style="color: red;">{{ requireNum }}</span>
      学时，选修
      <span style="color: red;">{{ chooseNum }}</span>
      学时
    </div>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="addOrModifyTrainingCourse" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'WxCourseManageEdit',
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      type: this.$route.params.type,
      activeName: 'first',
      //获取的课程分类、课程类型层级数组
      dataList: [],
      // 课程分类数组
      traineeTypeList: [],
      //课程类型数组
      trainTypeList: [],
      simpleCodeList: [], // 题目类别层级
      courseUid: '',
      msForm: {
        uuid: '',
        courseName: '',
        courseDesc: '',
        requiredClassHour: '',
        coverPicture: '',
        existExam: '',
        ifEnable: '',
        roleType: '', //课程分类    默认数据源第一项
        trainType: '', //课程类型
        annexList: [], //封面图片集合
        tbCoursewareInfoExpList: [], //课件信息集合
        offLineCoursewareList: [],
        // keyDate: new Date().getTime(),
      },
      coursewareDialogForm: {
        // 教材 or 考试 弹框
        coursewareType: '', //课件选择框 1:标题 2:教材 3:考试
        coursewareIndex: '', //课件列表标记
        coursewareTitle: '', //弹框选择的 教材名称/考试名称
        coursewareCurrentPage: '', // 弹框页码
        pageSize: 18,
        teachingMaterialId: '', // 教材id
        examId: '', // 考试id
        materialNameLike: '', //教材名称查询
        materialTotal: 0, // 教材分页总数
        classHour: '', //教材 学时
        teachingMaterialList: [], //教材集合
        examNameLike: '', //考试名称查询
        examTotal: 0, //考试分页总数
        examConfigList: [], //考试集合
        selectRadio: '', //弹框列表 选中
      },
      relatedDataDialogForm: {
        // 相关资料弹框
        relatedDataNameLike: '', //资料名称查询
        dataId: '', //资料id
        relatedDataTotal: 0,
        pageSize: 18,
        relatedDataCurrentPage: '',
        relatedDataList: [], // 弹框 列表集合
      },
      multipleSelection: [], //资料多选集合
      loading: null,
      // 校验规则
      rules: {
        courseName: [{ required: true, message: '请输入课程名称', trigger: 'blur' }],
        existExam: [{ required: true, message: '请选择是否包含考试', trigger: 'change' }],
        requiredClassHour: [{ required: true, message: '请输入必须学时数', trigger: 'blur' }],
        levelCodeNo: [{ required: true, validator: this.levelCodeNoValidate, trigger: ['change', 'blur'] }],
        coursewareType: [{ required: true, message: '请选择课件类别', trigger: ['change'] }],
        courseName: [{ required: true, message: '不能为空', trigger: ['change', 'blur'] }],
        roleType: [{ required: false, message: '课程分类不能为空', trigger: ['change', 'blur'] }],
        trainType: [{ required: false, message: '课程类型不能为空', trigger: ['change', 'blur'] }],
        classHour: [{ required: true, message: '请输入学时', trigger: ['change', 'blur'] }],
      },
      coursewareDialogFormRules: {},
      msFormOld: {},
      folder: '/onlineTraining', //文件存储路径
      // 选中的课件数组
      selectList: [],
      //初始化选择数组
      oldSelectList: [],
      //必修合计
      requireNum: 0,
      //选修合计
      chooseNum: 0,
      editType: this.$route.params.editType,
      //线下课时和
      offLineNum: 0,
      ifShowOff: true,
    }
  },
  created() {
    this.ifShowOff = window.top.vm.$store.state.config.ifTrainingClassOffline
  },
  mounted() {
    // 判断添加   还是编辑
    this.pageRouter()
    //课程分类、课程类型
    if (this.showFiled) {
      this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002', data => {
        this.dataList = this.manyListFul(data['5002'], '')
        this.traineeTypeList = this.dataList.map(item => {
          return Object.assign(
            {},
            {
              codeName: item.codeName,
              codeNo: item.codeNo,
            }
          )
        })
        // 课程分类默认数据第一项
        this.msForm.roleType = this.traineeTypeList.length > 0 && this.traineeTypeList[0].codeNo
        // 课程类型默认是数据第一项的下级数据
        this.dataList.forEach(item => {
          if (this.msForm.roleType == item.codeNo) {
            this.trainTypeList = item.children
          }
        })
        this.msForm.trainType = this.trainTypeList[0].codeNo
      })
      this.rules.roleType[0].required = true
      this.rules.trainType[0].required = true
    }
  },
  computed: {
    saveButtonDisabled() {
      let currentMsForm = { ...this.msForm }
      delete currentMsForm.keyDate
      if (JSON.stringify(currentMsForm) !== this.msFormOld || JSON.stringify(this.selectList) !== this.oldSelectList) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    'msForm.tbCoursewareInfoExpList': {
      handler() {
        let requireList = []
        let chooseList = []
        this.msForm.tbCoursewareInfoExpList.forEach(item => {
          if (!item.ifInUse) {
            return
          }
          if (item.requiredCourse) {
            requireList.push(item.classHour == undefined ? 0 : Number(item.classHour))
          } else {
            chooseList.push(item.classHour == undefined ? 0 : Number(item.classHour))
          }
        })
        if (requireList.length > 0) {
          this.requireNum = this.sum(requireList)
        } else {
          this.requireNum = 0
        }
        if (chooseList.length > 0) {
          this.chooseNum = this.sum(chooseList)
        } else {
          this.chooseNum = 0
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //勾选某行时
    rowSelectChange(selection, row) {
      this.selectList = selection
      // coursewareType     1 标题     2  教材
      this.msForm.tbCoursewareInfoExpList.forEach(item => {
        this.selectList.forEach(itemm => {
          if (itemm.coursewareType == 2) {
            let index = itemm.levelCodeNo.indexOf('.')
            let subStr = itemm.levelCodeNo.substring(0, index)
            if (item.levelCodeNo == subStr) {
              this.$refs.chooseTableRef.toggleRowSelection(item, true)
            }
          }
        })
      })

      this.msForm.tbCoursewareInfoExpList.forEach(item => {
        let index = ''
        let subStr = ''
        if (item.coursewareType == 2) {
          index = item.levelCodeNo.indexOf('.')
          subStr = item.levelCodeNo.substring(0, index)
          if (row.levelCodeNo == subStr) {
            this.$refs.chooseTableRef.toggleRowSelection(row, !row.ifInUse)
            this.$refs.chooseTableRef.toggleRowSelection(item, !row.ifInUse)
          }
        }
      })

      let arr = this.getArrDifference(this.selectList, this.msForm.tbCoursewareInfoExpList)
      let ridList = arr.map(item => {
        return item.rid
      })
      if (ridList.length == 0) {
        this.msForm.tbCoursewareInfoExpList.forEach(item => {
          item.ifInUse = true
        })
      } else {
        this.msForm.tbCoursewareInfoExpList.forEach((item, index) => {
          item.ifInUse = true
          ridList.forEach(itemm => {
            if (itemm == item.rid) {
              item.ifInUse = false
            }
          })
        })
        // let list = JSON.stringify(this.msForm.tbCoursewareInfoExpList)
        // this.msForm.tbCoursewareInfoExpList = JSON.parse(list)
      }
      let list = this.msForm.tbCoursewareInfoExpList.map(item => {
        return item.ifInUse
      })
      // 若子集全取消则父级也取消
      if (row.coursewareType == 2) {
        let startIndex = row.parent.indexId
        let num = row.parent.subNum
        let resultList = list.slice(startIndex + 1, startIndex + num + 1)
        let flag = resultList.every(item => item == false)
        row.parent.ifInUse = !flag
        this.$refs.chooseTableRef.toggleRowSelection(row.parent, !flag)
      }
      this.handlerList()
    },
    handlerList() {
      let requireList = []
      let chooseList = []
      this.msForm.tbCoursewareInfoExpList.forEach(item => {
        if (!item.ifInUse) {
          return
        }
        if (item.requiredCourse) {
          requireList.push(item.classHour == undefined ? 0 : Number(item.classHour))
        } else {
          chooseList.push(item.classHour == undefined ? 0 : Number(item.classHour))
        }
      })
      if (requireList.length > 0) {
        this.requireNum = this.sum(requireList)
      } else {
        this.requireNum = 0
      }
      if (chooseList.length > 0) {
        this.chooseNum = this.sum(chooseList)
      } else {
        this.chooseNum = 0
      }
    },
    //列表多选
    listSelectionChange(selectList) {
      this.selectList = selectList
    },
    // 全选、全不选
    allSelectChange() {
      if (this.selectList.length > 0) {
        this.msForm.tbCoursewareInfoExpList.forEach(item => {
          item.ifInUse = true
        })
      } else {
        this.msForm.tbCoursewareInfoExpList.forEach(item => {
          item.ifInUse = false
        })
      }
      this.handlerList()
    },
    //求和
    sum(arr) {
      return arr.reduce((prev, curr, idx, arr) => {
        return prev + curr
      })
    },
    selectable(row) {
      // 禁用  标题行
      return row.coursewareType == 1
    },
    // 课程分类change事件
    roleTypeChange(val) {
      this.dataList.forEach(item => {
        if (val == item.codeNo) {
          this.trainTypeList = item.children
        }
      })
      this.msForm.trainType = this.trainTypeList[0].codeNo
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    /**
     * 页面路由回调
     */
    pageRouter() {
      let title = this.type === 'add' ? '添加' : '编辑'
      // this.msFormOld = JSON.stringify(this.msForm)
      // this.rid = ''
      this.$emit('breadcrumb', title, true)
      this.courseUid = this.$route.params.courseUid
      this.getDetail(this.courseUid)
      // this.getTeachingMaterialList(1, null)
      // this.getExamConfigList(1, null)
    },
    /**
     * 获取详情
     */
    getDetail(courseUid) {
      let data = {
        uuid: courseUid,
        editType: this.editType,
      }

      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getTrainingCourseDetail-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            for (let key in data.trainingCourse) {
              this.msForm[key] = data.trainingCourse[key]
            }
            this.msForm.annexList = []
            this.msForm.uuid = this.courseUid
            this.msForm.courseName = data.trainingCourse.courseName
            this.msForm.requiredClassHour = data.trainingCourse.requiredClassHour
            this.msForm.courseDesc = data.trainingCourse.courseDesc
            this.msForm.existExam = data.trainingCourse.existExam
            this.msForm.ifEnable = data.trainingCourse.ifEnable
            this.msForm.coverPicture = data.trainingCourse.coverPicture
            this.msForm.traineeTypeName = data.trainingCourse.traineeTypeName
            this.msForm.trainingTypeName = data.trainingCourse.trainingTypeName
            this.msForm.offLineCoursewareList = data.trainingCourse.offLineCoursewareList || []
            if (data.trainingCourse.coverPicture) {
              this.msForm.annexList.push({
                filePath: data.trainingCourse.coverPicture,
              })
            }
            this.msForm.tbCoursewareInfoExpList = data.trainingCourse.tbCoursewareInfoExpList || []
            let aKeyObj = {}
            let index = ''
            let subStr = ''
            this.msForm.tbCoursewareInfoExpList.forEach((item, i) => {
              if (item.ifInUse) {
                this.selectList.push(item)
              }
              if (item.coursewareType == 1) {
                item.subNum = 0
                aKeyObj = item
                aKeyObj.indexId = i
              }
              index = item.levelCodeNo.indexOf('.')
              subStr = item.levelCodeNo.substring(0, index)
              if (aKeyObj.levelCodeNo == subStr) {
                aKeyObj.subNum++
                item.parent = aKeyObj
              }
            })
            this.oldSelectList = JSON.stringify(this.selectList)
            this.$nextTick(() => {
              this.selectList.forEach(item => {
                this.$refs.chooseTableRef.toggleRowSelection(item, true)
              })
            })
            this.msForm.tdCourseRelatedDataExpList = data.trainingCourse.tdCourseRelatedDataExpList || []
            if (this.showFiled) {
              this.msForm.roleType = data.configExp.traineeType
              this.dataList.forEach(item => {
                if (this.msForm.roleType == item.codeNo) {
                  this.trainTypeList = item.children
                }
              })
              this.msForm.trainType = data.configExp.trainingType
            }

            let newMsForm = { ...this.msForm }
            delete newMsForm.keyDate
            this.msFormOld = JSON.stringify(newMsForm)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 课程图片文件上传显示回调
     */
    picFileShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.msForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.annexList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid()
      }
    },
    /**
     * 课程图片文件上传成功回调
     */
    picFileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.msForm.annexList) {
          for (let j in fileList) {
            if (this.msForm.annexList[i].fileName === fileList[j].fileName) {
              this.msForm.annexList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      // 保存
      //this.saveData()
    },
    /**
     * 删除课程图片文件
     */
    deletionPicFile(index) {
      let fileName = this.msForm.annexList[index].fileName
      let filePath = this.msForm.annexList[index].filePath
      this.msForm.annexList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.coverPictureRef.deleteIndex(index)
      }
      let fileList = this.$refs.coverPictureRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.coverPictureRef.deleteIndex(j)
            fileList = this.$refs.coverPictureRef.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 课件添加
     */
    addInfo() {
      let data = {
        courseUid: '',
        courseName: '',
        classHour: '',
      }
      this.msForm.offLineCoursewareList.push(data)
      // this.$set(this.msForm, 'keyDate', new Date().getTime())
    },
    /**
     * 课件删除
     */
    deleteInfo(index) {
      this.$set(this.msForm, 'keyDate', new Date().getTime())
      this.msForm.offLineCoursewareList.splice(index, 1)
    },

    /**
     * 点击保存
     */
    addOrModifyTrainingCourse() {
      let offLineNumList = []
      this.msForm.offLineCoursewareList.forEach(item => {
        if (!this.ifShowOff) {
          offLineNumList.push(0)
        } else {
          offLineNumList.push(item.classHour == undefined ? 0 : Number(item.classHour))
        }
      })
      if (offLineNumList.length > 0) {
        this.offLineNum = this.sum(offLineNumList)
      } else {
        this.offLineNum = 0
      }
      this.$refs.msForm.validate(valid => {
        if (valid) {
          if (this.$zwxBase.verifyIsBlank(this.msForm.offLineCoursewareList) && this.ifShowOff) {
            this.$system.notify('错误', '线下课程不能为空', 'error')
            return
          }
          if (this.$zwxBase.verifyIsBlank(this.selectList)) {
            this.$system.notify('错误', '线上课程不能为空', 'error')
            return
          }
          if (this.offLineNum + this.requireNum + this.chooseNum < this.msForm.requiredClassHour) {
            this.$system.notify('错误', '课程总学时过少，请修改后提交', 'error')
            return
          }
          if (this.offLineNum + this.requireNum > this.msForm.requiredClassHour) {
            this.$system.notify('错误', '必修课程学时过多，请修改后提交', 'error')
            return
          }
          // 保存
          this.saveData()
        }
      })
    },
    // 获取上传题目图片文件的UID
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-1', data, true, true, data => {
        if (data.type === '00') {
          this.$refs.coverPictureRef.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },

    //获取两个数组中不同的数据项
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter((v, i, arr) => {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    // 上传文件之后 保存数据
    saveData() {
      // let arr = this.getArrDifference(this.selectList, this.msForm.tbCoursewareInfoExpList)
      // let ridList = arr.map(item => {
      //   return item.uuid
      // })
      // if (ridList.length == 0) {
      //   this.msForm.tbCoursewareInfoExpList.forEach(item => {
      //     item.ifInUse = true
      //   })
      // } else {
      //   this.msForm.tbCoursewareInfoExpList.forEach(item => {
      //     item.ifInUse = true
      //     ridList.forEach(itemm => {
      //       if (itemm == item.uuid) {
      //         item.ifInUse = false
      //       }
      //     })
      //   })
      // }
      let data = {
        ...this.msForm,
        editType: this.editType,
      }
      if (this.editType === 1) {
        delete data.uuid
      }
      delete data.keyDate
      this.$system.postJson(
        this.api + '/training/platform/copyTrainingCourse-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$router.push({
              name: 'WxCourseManage',
              params: { fresh: true },
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            this.$emit('loading', false)
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
            this.$emit('loading', false)
          }
        },
        this.error
      )
    },
    /**
     * 教材列表查询
     */
    getTeachingMaterialList(currentPage, coursewareIndex) {
      this.coursewareDialogForm.coursewareCurrentPage = currentPage
      var filterRidList = []
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.tbCoursewareInfoExpList)) {
        for (let index = 0; index < this.msForm.tbCoursewareInfoExpList.length; index++) {
          const element = this.msForm.tbCoursewareInfoExpList[index]
          if (this.$zwxBase.verifyIsNotBlank(element.coursewareType) && element.coursewareType === 2 && this.$zwxBase.verifyIsNotBlank(element.teachingMaterialId) && this.$zwxBase.verifyIsNotBlank(coursewareIndex) && coursewareIndex !== index) {
            filterRidList.push(element.teachingMaterialId)
          }
        }
      }
      let data = {
        currentPage: this.coursewareDialogForm.coursewareCurrentPage,
        fileName: this.coursewareDialogForm.materialNameLike,
        categoryCodes: '1',
        ifEnable: true,
        filterRids: this.$zwxBase.verifyIsNotBlank(filterRidList) ? filterRidList.join(',') : null,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/online/training/getTeachingMaterialList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.coursewareDialogForm.teachingMaterialList = data.teachingMaterialList
            this.coursewareDialogForm.materialTotal = data.teachingMaterialCount
            this.coursewareDialogForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 考试列表查询
     */
    getExamConfigList(currentPage, coursewareIndex) {
      this.coursewareDialogForm.coursewareCurrentPage = currentPage
      var examRidList = []
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.tbCoursewareInfoExpList)) {
        for (let index = 0; index < this.msForm.tbCoursewareInfoExpList.length; index++) {
          const element = this.msForm.tbCoursewareInfoExpList[index]
          if (this.$zwxBase.verifyIsNotBlank(element.coursewareType) && element.coursewareType === 3 && this.$zwxBase.verifyIsNotBlank(element.examId) && this.$zwxBase.verifyIsNotBlank(coursewareIndex) && coursewareIndex !== index) {
            examRidList.push(element.examId)
          }
        }
      }
      let data = {
        currentPage: this.coursewareDialogForm.coursewareCurrentPage,
        examName: this.coursewareDialogForm.examNameLike,
        ifEnable: true,
        examRidList: this.$zwxBase.verifyIsNotBlank(examRidList) ? examRidList.join(',') : null,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/online/training/getExamConfigList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.coursewareDialogForm.examConfigList = data.examConfigList
            this.coursewareDialogForm.examTotal = data.examConfigCount
            this.coursewareDialogForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.wx-course-manage-edit {
  /deep/.zwx-table {
    border-left: 1px solid #ebeef5 !important;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #4a75f5 !important;
    border-color: #4a75f5 !important;
  }
}

/deep/ .topic-select > .el-input.el-input--suffix > .el-input__inner {
  width: 100px !important;
}
/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: none;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 40px !important;
}
.courseware-dialog .el-dialog__footer {
  border-top: unset !important;
}

/deep/.courseware-dialog .el-radio.zwx-radio-course > .el-radio__input + .el-radio__label {
  display: none;
}
.relatedData-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      width: 600px !important;
      min-width: 600px !important;
    }
  }
}
</style>
