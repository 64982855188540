var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wx-course-manage-edit" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "课程基本信息" }
          }),
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "课程名称：",
                      prop: "courseName"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "640px !important" },
                      attrs: {
                        placeholder: "请输入",
                        clearable: "",
                        maxlength: "50"
                      },
                      model: {
                        value: _vm.msForm.courseName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "courseName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.courseName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "课程简介：",
                      prop: "courseDesc"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-textarea",
                      staticStyle: { width: "640px !important" },
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 5, maxRows: 8 },
                        maxlength: "2000",
                        placeholder: "请输入",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msForm.courseDesc,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "courseDesc",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.courseDesc"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "110px", label: "学时要求：" } },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "120px", color: "#191919" } },
                      [_vm._v(_vm._s(_vm.msForm.requiredClassHour))]
                    )
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "80px", label: "封面图片：" } },
                  [
                    _c("file-upload", {
                      ref: "coverPictureRef",
                      attrs: {
                        accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,",
                        size: 50 * 1024 * 1024,
                        sizeInfo: "50M"
                      },
                      on: {
                        showFileList: _vm.picFileShowFileList,
                        success: _vm.picFileUploadSuccess
                      }
                    }),
                    _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.annexList)
                      ? _c(
                          "div",
                          { staticClass: "show-file" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "zwx-button zwx-button-text-26",
                                on: {
                                  click: function($event) {
                                    return _vm.openFilePreview1(
                                      _vm.msForm.annexList,
                                      0
                                    )
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "110px", label: "课程分类：" } },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "120px", color: "#191919" } },
                      [_vm._v(_vm._s(_vm.msForm.traineeTypeName))]
                    )
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "80px", label: "课程类型：" } },
                  [_c("div", [_vm._v(_vm._s(_vm.msForm.trainingTypeName))])]
                )
              ],
              1
            ),
            _vm.ifShowOff
              ? _c(
                  "div",
                  { staticClass: "condition-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { "label-width": "110px", label: "线下课程：" }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-icontext-28",
                            attrs: { icon: "el-icon-plus" },
                            on: { click: _vm.addInfo }
                          },
                          [_vm._v("添加")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.ifShowOff
              ? _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-table",
                      {
                        staticClass: "zwx-table",
                        attrs: {
                          "row-key": "rid",
                          data: _vm.msForm.offLineCoursewareList,
                          border: "",
                          stripe: "",
                          "tooltip-effect": "light"
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "courseName",
                            label: "课程名称",
                            "min-width": "300",
                            "header-align": "center",
                            align: "left"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "courseName" + scope.$index,
                                          prop:
                                            "offLineCoursewareList." +
                                            scope.$index +
                                            ".courseName",
                                          rules: _vm.rules.courseName
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "50"
                                          },
                                          model: {
                                            value: scope.row.courseName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "courseName",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.courseName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1350349651
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "classHour",
                            label: "学时",
                            width: "150",
                            "header-align": "center",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "classHour" + scope.$index,
                                          prop:
                                            "offLineCoursewareList." +
                                            scope.$index +
                                            ".classHour",
                                          rules: _vm.rules.classHour
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          attrs: {
                                            placeholder: "",
                                            clearable: ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.$validate.number(
                                                scope.row,
                                                "classHour",
                                                3,
                                                1,
                                                0,
                                                100
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.classHour,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "classHour",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "scope.row.classHour"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3338288966
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            fixed: "right",
                            "header-align": "left",
                            align: "left"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "zwx-button zwx-button-text-26",
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteInfo(scope.$index)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            626612973
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "condition-row",
                staticStyle: { "margin-top": "16px" }
              },
              [
                _c("el-form-item", {
                  attrs: { "label-width": "110px", label: "线上课程：" }
                })
              ],
              1
            ),
            _c(
              "div",
              {},
              [
                _c(
                  "el-table",
                  {
                    ref: "chooseTableRef",
                    staticClass: "zwx-table",
                    attrs: {
                      "row-key": "uuid",
                      data: _vm.msForm.tbCoursewareInfoExpList,
                      border: "",
                      stripe: "",
                      "tooltip-effect": "light"
                    },
                    on: {
                      "selection-change": _vm.listSelectionChange,
                      "select-all": _vm.allSelectChange,
                      select: _vm.rowSelectChange
                    }
                  },
                  [
                    _c("el-table-column", {
                      key: "selection",
                      attrs: {
                        type: "selection",
                        width: "55",
                        "header-align": "center",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "coursewareType",
                        label: "类别",
                        width: "80",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.coursewareType == 1
                                      ? "标题"
                                      : scope.row.coursewareType == 2
                                      ? "教材"
                                      : "考试"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "coursewareTitle",
                        label: "内容",
                        "min-width": "270",
                        "header-align": "center",
                        align: "left"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_vm._v(_vm._s(scope.row.coursewareTitle))]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "classHour",
                        label: "学时",
                        width: "150",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(" " + _vm._s(scope.row.classHour) + " ")
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "requiredCourse",
                        label: "性质",
                        "header-align": "left",
                        align: "left"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.requiredCourse === true
                                      ? "必修"
                                      : scope.row.requiredCourse === false
                                      ? "选修"
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "margin-left": "40px",
            "margin-top": "10px",
            "font-size": "18px",
            color: "#3A485E"
          }
        },
        [
          _vm._v(" 已选课程合计：必修 "),
          _c("span", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.requireNum))
          ]),
          _vm._v(" 学时，选修 "),
          _c("span", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.chooseNum))
          ]),
          _vm._v(" 学时 ")
        ]
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.addOrModifyTrainingCourse }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }