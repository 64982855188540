<template>
  <div class="ms-login">
    <div class="top-title" ref="topTitleRef">
      <!-- <div style="width:10px;height:10px"></div> -->
      <!-- <img src="../../assets/images/system/img_login_title.png" alt="" style="width:638px;height:89px;margin-top:8vh" /> -->
    </div>
    <div class="box-flex">
      <div class="login-background" ref="loginBackgroundRef">
        <div class="title">
          经济开发区职业健康智慧管家系统
        </div>
        <div v-if="loginType==1">
          <div class="login-middle">
          <div>
            <img src="@/assets/images/login/ic_manage.png" alt="" style=" margin-left: 100px;" />
            企业管理
          </div>
          <div>
            <img src="@/assets/images/login/ic_business.png" alt="" />
            业务申报
          </div>
        </div>
        <div class="login-bcImg">
          <img src="@/assets/images/login/img_employer.png" alt="" class="login-bc-pic" />
        </div>
        </div>
        <div v-if="loginType==2">
          <div class="login-middle">
          <div>
            <img src="@/assets/images/login/ic_auxiliary.png" alt="" style=" margin-left: 100px;" />
            辅助决策
          </div>
          <div>
            <img src="@/assets/images/login/ic_business.png" alt="" />
            统计分析
          </div>
        </div>
        <div class="login-bcImg">
          <img src="@/assets/images/login/img_supervise.png" alt="" class="login-bc-pic" />
        </div>
        </div>
        <div v-if="loginType==3">
          <div class="login-middle">
          <div>
            <img src="@/assets/images/login/ic_detection.png" alt="" style=" margin-left: 100px;" />
            场所检测
          </div>
          <div>
            <img src="@/assets/images/login/ic_help.png" alt="" />
            惠企帮扶
          </div>
        </div>
        <div class="login-bcImg">
          <img src="@/assets/images/login/img_service.png" alt="" class="login-bc-pic" />
        </div>
        </div>
     

        <!-- <img :src="require('../../assets/images/loginGif/red.gif'.replace('on', 'off'))" class="gif-img-red" />
        <img :src="require('../../assets/images/loginGif/jiandu.gif'.replace('on', 'off'))" class="gif-img-jiandu" />
        <img :src="require('../../assets/images/loginGif/lan.gif'.replace('on', 'off'))" class="gif-img-lan" />
        <img :src="require('../../assets/images/loginGif/peixun.gif'.replace('on', 'off'))" class="gif-img-peixun" />
        <img :src="require('../../assets/images/loginGif/wenjian.gif'.replace('on', 'off'))" class="gif-img-wenjian" />
        <img :src="require('../../assets/images/loginGif/yujing.gif'.replace('on', 'off'))" class="gif-img-yujin" /> -->
      </div>
      <div class="content-box">
        <div class="login-back">
          <el-form key="msForm" ref="msForm" :model="msForm" :rules="rules">
            <div class="logo-title">
              <div style="width: 100%;">{{loginType==1?'用人单位端':loginType==2?'监管部门端':loginType==3?'服务机构端':loginType==4?'乡镇':'其他'}}</div>
              <div class="logo-title-bottom"></div>
              <!-- <div class="register-account" @click="toRegister">注册账号</div> -->
            </div>
            <div class="input">
              <el-form-item prop="userNo">
                <el-input placeholder="请输入登录账号" v-model="msForm.userNo">
                  <img slot="prefix" src="@/assets/images/login/ic_zhanghu.png" style="width:20px;height:27px" />
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <el-form-item prop="password">
                <el-input placeholder="请输入登录密码" v-model="msForm.password" :type="view ? 'password' : null" @keyup.enter.native="login('msForm')">
                  <img slot="prefix" src="@/assets/images/login/ic_mima.png" style="width:20px;height:27px" />
                  <i slot="suffix" class="el-input__icon el-icon-view" @click="passSee()"></i>
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <el-form-item prop="mobileTel4">
                <el-input placeholder="请输入手机号码后4位" v-model="msForm.mobileTel4">
                  <!-- <img slot="prefix" src="@/assets/images/login/ic_zhanghu.png" style="width:20px;height:27px" /> -->
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <slider-check ref="slider" style="width:354px" @change="sliderSuccess"></slider-check>
            </div>
            <!-- <div class="input">
              <image-verification ref="loginVerification" :uid="uid" style="width: 290px;" @success="msEnterpriseLoginSuccessVerification"></image-verification>
              <div class="warningtext">{{ tip }}</div>
            </div> -->
            <div class="footer">
              <el-button style="background: #1C7FF9;height: 56px;" type="primary" @click="login('msForm')">登录</el-button>
            </div>
          </el-form>
          <div class="more" style="margin-top: 24px;">
           
            <span class="left" @click="toAccountAppeal">
              账号申诉
            </span>
            <span class="right center left" @click="toForgetPassword">
              忘记密码
            </span>
            <span class="right"  @click="toRegister">注册账号</span>
          </div>

          <div class="flex-column-center">
            <el-popover placement="right" trigger="hover" popper-class="operat-popover">
              <div class="flex-column-center">
                <div class="manual-css" @click="downloadFile(contactEmployerPdf)">用人单位</div>
                <div class="manual-css" @click="downloadFile(contactSupervisePdf)">监管部门</div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-text">Copyright © {{ domainNumber }} | {{ technicalSupport }}</div>
    </div>

    <!-- <modify-user-password-dialog ref="modifyUserPasswordDialogNoShowOldPassword" :showOldPassword="false" :specifyUserUuid="userUuid"></modify-user-password-dialog> -->
    <contact-us />
  </div>
</template>

<script>
import imageVerification from '@/components/business/imageVerification'
export default {
  name: 'MsLogin',
  components: { imageVerification },
  data() {
    return {
      api: this.$store.state.api,
      contactEmployerPdf: this.$store.state.contactEmployerPdf,
      contactSupervisePdf: this.$store.state.contactSupervisePdf,
      ssoUrl: this.$store.state.ssoUrl,
      domainNumber: this.$store.state.domainNumber,
      technicalSupport: this.$store.state.technicalSupport,
      view: true,
      msForm: {
        userNo: '',
        password: '',
        code: '',
      },
      // images: [swiperImg1],
      rules: {
        userNo: [{ required: true, message: '请输入登录账号', trigger: ['change', 'blur'] }],
        password: [{ required: true, message: '请输入登录密码', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '请输入验证码', trigger: ['change', 'blur'] }],
        mobileTel4: [{ required: true, message: '请输入手机号码后4位', trigger: ['change', 'blur'] }]
      },
      loading: null,
      uid: this.baseGenUuid(32),
      imageUrl: '',
      keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
      userUuid: '',
      initPasswordUid: '',
      tip: '',
      isSucess: false,
      sliderTokenId:null,
      loginType:1
    }
  },
  created() {
    //   this.$zwxCookie.delCookie('msTokenId')
    this.verification()
  },
  mounted() {
    // this.resetLoginVerification()
    if(this.$route.query.loginType){
      this.loginType=this.$route.query.loginType
    }
  },
  computed: {},
  methods: {
    // 点击注册
    toRegister() {
      // this.$router.push({ name: 'Register', params: {} })
      this.$router.push({ name: 'selectRegister', params: {} })
    },
    // 点击账号申诉
    toAccountAppeal() {
      this.$router.push({ name: 'AccountAppeal', params: {} })
    },
    // 忘记密码
    toForgetPassword() {
      this.$router.push({ name: 'ForgetPassword', params: {} })
    },
    //重置图形验证码
    resetLoginVerification() {
      this.uid = this.baseGenUuid(32)
      this.$nextTick(function() {
        this.$refs['loginVerification'].refresh()
      })
      this.isSucess = false
    },
    //图形验证成功返回值
    msEnterpriseLoginSuccessVerification(val) {
      if (val.isSucess) {
        this.tip = ''
      }
      this.isSucess = val.isSucess
    },
    verification() {
      this.uid = this.baseGenUuid(32)
      this.imageUrl = this.api + '/systematic/tools/imgVerifyCode-0' + '?uid=' + this.uid
    },
    passSee() {
      this.view = !this.view
    },
     // 滑块change
     sliderSuccess(val) {
      if (!this.msForm.userNo) {
        this.errorSlider = '请输入登录账号'
        this.$refs.slider && this.$refs.slider.initSliderCheck()
      } else {
        this.errorSlider = ''
        val.userNo = this.$zwxSm.sm4JsEncrypt(this.msForm.userNo)
        let data = val
        //  let api=this.loginType==2?'/basic/info/userLoginWithSliderSupervise-0':this.loginType==3?'/basic/info/userLoginWithSliderService-0':'/basic/info/userLoginWithSliderOther-0'
       this.$system.postJson(
          this.api + '/systematic/getLoginSliderToken-0',
          data,
          false,
          true,
          data => {
            if (data.type === '00') {
              this.sliderTokenId = this.$zwxSm.sm2Encrypt123(this.$zwxSm.sm2Decrypt123(data.sliderTokenId))
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$refs.slider && this.$refs.slider.initSliderCheck()
              this.$system.notify('错误', data.mess, 'error')
            }
            this.$emit('loading', false)
          }, this.error)
      }
    },
    login(val) {
      // 滑块校验
      const flag = this.$refs.slider && !this.$refs.slider.confirmSuccess
        if (flag) {
          this.errorSlider = '请拖动滑块验证'
        } else {
          this.errorSlider = ''
        }
      this.$refs[val].validate(valid => {
        if (valid) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          // 27aa9771904d7d45：用人单位，5ec06fb2c9bfd9b0：监管用户 ，a47694a02ed7b7fc：技术服务机构
          // var roleCode=this.loginType==1?'27aa9771904d7d45':this.loginType==2?'5ec06fb2c9bfd9b0':'a47694a02ed7b7fc'
          let data = {
            userNo: this.$zwxSm.sm4JsEncrypt(this.msForm.userNo),
            sliderTokenId: this.sliderTokenId,
            password: this.$zwxSm.sm2Encrypt123(this.msForm.password),
            mobileTel4:this.msForm.mobileTel4,
            // roleCode:this.$zwxSm.sm4JsEncrypt(roleCode)
          }
            // loginType 1'用人单位端'2'监管部门端'3'服务机构端'4'乡镇': 剩余其他 
            let api=this.loginType==2?'/basic/info/userLoginWithSliderSupervise-0':this.loginType==3?'/basic/info/userLoginWithSliderService-0':this.loginType==1?'/basic/info/userLoginWithSliderEmployer-0':this.loginType==4?'/basic/info/userLoginWithSliderTowns-0':'/basic/info/userLoginWithSliderOther-0'
            this.$system.post(this.api +api, data, false, true, this.success, this.error)
        }
      })
    },
    success(data) {
      try {
        if (data.type === '00') {
          this.$zwxCookie.addCookie('msTokenId', data.tokenId)
          if(data.roleList.length){
            this.$zwxCookie.addCookie('userRole',data.roleList[0].roleCode)
          }else{
            if(data.ifSuperAdmin){

            }else{
               this.$system.notify('未查询到该账号存在角色', data.mess, 'error')
               return
            }
           
          }
          window.localStorage.setItem('role', JSON.stringify(data.roleList))
          this.$store.commit('ADDROLELIST',data.roleList)
         
          this.$router.push({ name: 'DefaultLayout', params: { password: this.msForm.password, userNo: this.msForm.userNo } })
          window.localStorage.setItem('WJUN', this.msForm.userNo)
          window.localStorage.setItem('WJPD', this.msForm.password)
          
          this.loading.close()
        } else if (data.type === '03') {
          this.initPasswordUid = JSON.parse(this.$zwxSm.sm4JsDecrypt(data.initPasswordUid))
          this.userUuid = this.initPasswordUid.uid
          this.$refs.modifyUserPasswordDialogNoShowOldPassword.modifyPassword()
          this.isSucess = false
          // this.$refs['loginVerification'].refresh()
          this.$system.notify('错误', data.mess, 'error')
        } else {
          this.isSucess = false
          this.$system.notify('错误', data.mess, 'error')
          // this.$refs['loginVerification'].refresh()
          this.verification()
        }
        this.loading.close()
      } catch (e) {
        console.log(e)
      }
    },
    verificationError() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.loading.close()
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.loading.close()
    },
    /**
     * 生成uuid
     * len：uuid长度
     *
     * */
    baseGenUuid(len) {
      var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
      var uuid = [],
        i
      if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * chars.length)]
      } else {
        var r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'
        for (i = 0; i < 36; i++) {
          if (typeof uuid[i] === 'undefined') {
            r = 0 | (Math.random() * 16)
            uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
          }
        }
      }
      return uuid.join('')
    },
    downloadFile(filePath) {
      if (filePath.indexOf('http') != -1) {
        window.open(filePath)
      } else {
        window.open(this.file + filePath)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  font-size: 46px;
  font-weight: 600;
  font-family: FZRuiZhengHeiS-DB-GB, FZRuiZhengHeiS-DB-GB-Regular;
  font-weight: Regular;
  text-align: left;
  color: #2c2c2d;
  line-height: 53px;
  letter-spacing: 0.06px;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ms-login {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/login/img_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .login-bcImg {
    width: 520px;
    height: 520px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-bc-pic {
    width: 370px;
    height: 370px;
  }
}
.warningtext {
  padding-top: 5px;
  height: 15px;
  color: #ff4949;
  font-size: 12px;
  line-height: 15px;
}
.operat-manual {
  width: 72px;
  cursor: pointer;
  &:hover {
    .text-css-span {
      color: #2caef9;
    }
  }
}

.top-title {
  min-height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.more {
  margin-top: 16px;
  display: flex;
  font-size: 16px;
  line-height: 14px;
  justify-content: flex-end;
  align-items: center;
  color:#909399;
  span:hover{
    color: #3e71f7;
  }
  
  .center{
    border-left: 1px solid #909399;
    border-right: 1px solid #909399;
  }
  .right{
    padding-left: 20px;
    cursor: pointer;
  
  }
  .left {
    padding-right: 20px;
    cursor: pointer;
  }
}

.help-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #3689ff;
  line-height: 14px;
  cursor: pointer;
}
.head {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  color: #999999;
  .bottom-text {
    position: fixed;
    bottom: 10px;
    color: #fff;
  }
}

.box-flex {
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  margin-top: 80px;
}
.login-background {
  width: 724px;
  height: 766px;
  // background-image: url('../../assets/images/system/login_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .login-middle {
    margin-top: 38px;
    margin-bottom: 11px;
    display: flex;
    height: 29px;
    line-height: 30px;
    align-items: center;
    font-size: 30px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: Regular;
    text-align: left;
    color: #5e6c7c;
    div {
      flex: 1;
      display: flex;
      align-items: center;
    }
    img {
      width: 30px;
      height: 30px;
      margin-left: 60px;
      margin-right: 23px;
    }
    & div:nth-child(1) {
      border-right: 1px solid #5e6c7c;
    }
  }
}

.content-box {
  width: 536px;
  height: 645px;
  background-image: url('../../assets/images/system/img_form_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 64px;

  .login-back {
    padding: 50px 88px 0 88px;
    .input {
      /deep/ .el-input__inner {
        height: 56px !important;
        line-height: 60px !important;
      }
      .verification {
        width: 80px;
        margin-top: 5px;
      }
      .verificationIcon {
        width: 15px;
        margin: 13px 0 0 5px;
      }
    }
    .logo-title {
      position: relative;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 500;
      width: 100%;
      text-align: center;
      color: #27274a;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 30px 0 50px;
      .logo-title-bottom{
        margin-top: 12px;
        width: 60px;
        height: 4px;
        background: #0278ff;
        border-radius: 2px;
      }
      .register-account {
        font-size: 14px;
        color: #3689ff;
        letter-spacing: 0.88px;
        align-self: flex-end;
        cursor: pointer;
      }
    }
    .footer {
      width: 100%;
      .el-button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}

/deep/.el-input__prefix {
  left: 12px !important;
  display: flex;
  align-items: center;
  height: 24px;
  top:16px;
  padding-right: 15px;
  border-right: 1px solid #b3b3b3;
}
/deep/.el-input__inner {
  padding-left: 70px !important;
}

.dialog-custom .el-dialog {
  min-width: 0 !important;
  width: 420px !important;
}

.dialog-custom .dialog-password-title {
  font-size: 13px !important;
  color: #475669 !important;
  font-weight: 400 !important;
  margin-left: 20px !important;
  margin-top: 10px !important;
}

.dialog-custom .el-icon-warning {
  color: #4494f9 !important;
}
</style>

<style lang="less">
.operat-popover {
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(211, 221, 233, 0.4) !important;
  .manual-css {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 12px;
    padding: 16px 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e7e3e3;
    cursor: pointer;
    &:hover {
      color: #2caef9;
    }
  }
}
</style>
